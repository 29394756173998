<template>
  <div class="list">
    <list-search @onSearch="onSearch" @clearSearch="clearSearch" :childData="childData"></list-search>
    <global-table :tableField="tableField" @tableAction="tableAction" :tableData="tableData" :paginationData="paginationData" @pageChange="pageChange"
    ></global-table>
  </div>
</template>

<script>
import Component from '@/assets/js/components.js'
import { SearchList, SearchData, tableField, tableData } from './js/setting.js'
import { request } from '@/assets/js/http.js'
export default {
  components: Component.components,
  name: 'StocksInventory',
  data () {
    return {
      tableField: tableField,
      tableData: tableData,
      paginationData: {},
      childData: { searchList: SearchList, searchData: SearchData }
    }
  },
  created () {
    this.myBidData()
  },

  methods: {
    // 获取我的投标列表数据
    myBidData () {
      var pageSize = parseInt((document.documentElement.clientHeight - 330) / 42)
      if (pageSize < 10) {
        pageSize = 10
      }
      const obj = {
        bidCode: this.childData.searchData.myCode
      }
      request('/api/bid/myBidList?' + 'pageNum=' + 1 + '&' + 'pageSize=' + pageSize, 'POST', obj).then((res) => {
        if (res.code === '200') {
          this.tableData = res.data.records
          this.paginationData = { pageSize: pageSize, total: res.data.total }
        }
      })
    },
    // 点击操作里的按钮进行操作
    tableAction: function (index, data, clickBtn) {
      if (clickBtn.operationButton === 'see') {
        this.$router.push({ params: { viewName: 'MyBidRating' }, query: { objData: data.id } })
      } else {
      }
    },
    // 查询
    onSearch (data) {
      this.myBidData()
    },
    // 清空
    clearSearch (data) {
      this.myBidData()
    },
    // 分页
    pageChange (data) {
      this.paginationData.pageSize = data
      this.myBidData()
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/tablePadding.scss';
</style>
