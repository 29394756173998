export var SearchList = [
  { labe: '盘点单编号', code: 'myCode', type: 'input' },
  {
    labe: '入库时间',
    code: 'rkTime',
    type: 'daterange'
  },
  {
    labe: '库位',
    code: 'kw',
    type: 'inputAutoComplete',
    triggerOnFocus: false,
    fetchSuggestions: function (queryString, cb) {
      var scs = [{ value: 3 }]
      // var scs = [queryString]
      cb(scs)
    }
  }
]
export var SearchData = {
  myCode: '',
  rkTime: '',
  kw: ''
}
export var tableField = [
  { label: '盘点单编号', code: 'bidCode', type: 'input', width: '' },
  { label: '盘点日期', code: 'itemName', type: 'input', width: '' },
  { label: '库位', code: 'itemName', type: 'input', width: '' },
  { label: '操作人', code: 'itemName', type: 'input', width: '' },
  { label: '备注', code: 'itemName', type: 'input', width: '' },
  {
    label: '操作',
    code: 'act',
    width: '',
    type: 'action',
    actionData: [
      {
        actionLabel: '查看',
        operationButton: 'see'
      }
    ]
  }
]

export var tableData = []
